<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="max-height:500px;overflow:auto;">

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">仓库名称 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <b-form-select class="form-control" v-model="data_form.warehouse_id" :options="warehouse_list"  name="warehouse_id">

                        </b-form-select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">货架编号 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="推荐使用 货架号-层号-列号标记" v-model="data_form.shelf_number" name="shelf_number">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">描述:</label>
                    <div class="col-lg-6">
                        <textarea class="form-control" placeholder="请输入货架描述" v-model="data_form.description" name="description"></textarea>
                        <span class="form-text text-muted"></span>
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>

    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";

    import {getWareHouse, addShelf} from '@/api/warehouse';

    export default {
        name: "warehouse_add",
        props: {
            title: String,
            dialog_data: Object
        },
        created:function() {
            var _this = this;
            getWareHouse().then(function(res) {
                if(res && res.response){
                    _this.warehouse_list = KTUtil.listToSelectOptions(res.response, 'id', 'name');
                }
            });
        },
        components :{

        },
        data() {
            return {
                data_form:{
                    warehouse_id: '',
                    shelf_number: '',
                    description: ''
                },
                warehouse_list: []
            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {
                    warehouse_id: {
                        validators: {
                            notEmpty: {
                                message: "请选择仓库"
                            }
                        }
                    },
                    shelf_number: {
                        validators: {
                            notEmpty: {
                                message: "货架编号不能为空"
                            }
                        }
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                addShelf(_this.data_form).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        data_form.reset();
                        _this.$emit('opeareResultHandler', 1);
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            regionChange (data) {
                this.data_form.struct_address = data;
            }
        }
    }
</script>
